export enum LanguageList {
  af = "Afrikaans",
  sq = "Shqiptar",
  am = "ኣማርኛ",
  ar = "عربى",
  hy = "հայերեն",
  az = "Azərbaycan",
  eu = "Euskal",
  be = "Беларус",
  bn = "বাংলা",
  bs = "Bosanski",
  bg = "български",
  ca = "Català",
  ceb = "Cebuano",
  zh = "中文",
  co = "Corsu",
  hr = "Hrvatski",
  cs = "čeština",
  da = "dansk",
  nl = "Nederlands",
  en = "English",
  eo = "Esperanto",
  et = "Eestlane",
  fi = "Suomalainen",
  fr = "Français",
  fy = "Frysk",
  gl = "Galego",
  ka = "ქართული",
  de = "Deutsch",
  el = "Ελληνικά",
  gu = "ગુજરાતી",
  ht = "Kreyòl Ayisyen",
  ha = "حَوْسَ",
  haw = "Ōlelo Hawaiʻi",
  he = "עברית",
  hi = "हिन्दी",
  hmn = "lol Hmongb",
  hu = "Magyar",
  is = "Íslensku",
  ig = "Ndi Igbo",
  id = "bahasa Indonesia",
  ga = "Gaeilge",
  it = "Italiano",
  ja = "日本語",
  jv = "Basa Jawa",
  kn = "ಕನ್ನಡ",
  kk = "Қазақ",
  km = "ខ្មែរ",
  rw = "Ikinyarwanda",
  ko = "한국어",
  ku = "Kurdî",
  ky = "Кыргызча",
  lo = "ລາວ",
  la = "Latine",
  lv = "Latvietis",
  lt = "Lietuvis",
  lb = "Lëtzebuergesch",
  mk = "Македонски",
  mg = "Malagasy",
  ms = "Bahasa Melayu",
  ml = "മലയാളം",
  mt = "Malti",
  mi = "Maori",
  mr = "मराठी",
  mn = "Монгол",
  my = "ဗမာ",
  ne = "नेपाली",
  no = "norsk",
  ny = "Nyanja",
  or = "ଓଡିଆ",
  ps = "پښتو",
  fa = "فارسی",
  pl = "Polskie",
  pt = "Português",
  pa = "ਪੰਜਾਬੀ",
  ro = "Română",
  ru = "Pусский",
  sm = "Sāmoa",
  gd = "Albannach",
  sr = "Српски",
  st = "Sesotho",
  sn = "Shona",
  sd = "سنڌي",
  si = "සිංහල",
  sk = "slovenský",
  sl = "Slovenščina",
  so = "Soomaali",
  es = "Español",
  su = "ᮘᮞ ᮞᮥᮔ᮪ᮓ",
  sw = "Kiswahili",
  sv = "Svenska",
  tl = "ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
  tg = "Точик",
  ta = "தமிழ்",
  tt = "Татар",
  te = "తెలుగు",
  th = "ภาษาไทย",
  tr = "Türk",
  tk = "Türkmenler",
  uk = "Українська",
  ur = "اردو",
  ug = "ئۇيغۇر",
  uz = "O'zbek",
  vi = "Tiếng Việt",
  cy = "Cymraeg",
  xh = "isiXhosa",
  yi = "יידיש",
  yo = "Yorùbá",
  zu = "IsiZulu"
}

import { ThemeData } from "@/models/ThemeData"
import Color from "@/services/Color"
export default function getCssVariables(theme_data: ThemeData, rootcolor: string | undefined = undefined) {
  let color = new Color(theme_data.theme_color)
  let bgColor = rootcolor == undefined ? undefined : new Color(rootcolor)
  let darkercolor = color.getDarketVersion()
  let fontcolor = new Color(theme_data.font_color)
  return {
    "--font-color": theme_data.font_color,
    "--bg-contrast-font-color": bgColor == undefined ? theme_data.font_color : bgColor.islight() ? `#000000` : `#ffffff`,
    "--font-size": theme_data.font_size + "pt", //default is 11pt
    "--font-family": theme_data.font_family,
    "--theme-color": theme_data.theme_color,
    "--theme-contrast": color.islight() ? `#000000` : `#ffffff`,
    "--theme-contrast-6": color.islight() ? `#00000099` : `#ffffff99`,
    "--theme-contrast-brightness": color.islight() ? `rgba(0,0,0,0.2)` : `rgba(255,255,255,0.2)`,
    "--theme-color-2": `rgba(${color.r},${color.g},${color.b},0.2)`,
    "--theme-color-3": `rgba(${color.r},${color.g},${color.b},0.3)`,
    "--theme-color-4": `rgba(${color.r},${color.g},${color.b},0.4)`,
    "--theme-color-5": `rgba(${color.r},${color.g},${color.b},0.5)`,
    "--theme-color-9": `rgba(${color.r},${color.g},${color.b},0.9)`,
    "--theme-color-6": `rgba(${color.r},${color.g},${color.b},0.6)`,
    "--theme-color-7": `rgba(${color.r},${color.g},${color.b},0.7)`,
    "--theme-color-8": `rgba(${color.r},${color.g},${color.b},0.8)`,
    "--theme-color-1": `rgba(${color.r},${color.g},${color.b},0.1)`,
    "--font-color-6": `rgba(${fontcolor.r},${fontcolor.g},${fontcolor.b},0.6)`,
    "--font-color-2": `rgba(${fontcolor.r},${fontcolor.g},${fontcolor.b},0.2)`,
    "--lightbox-bg-color": `rgba(${darkercolor[0]},${darkercolor[1]},${darkercolor[2]},0.96)`,
    "font-size": `var(--font-size)`
  }
}

import { singleton, inject } from "tsyringe"
import { SendAnalytics } from "@/services/SendAnalytics"
import { AwEventV2 } from "@/models/AwEventV2"

import { AwEventStoreV2 } from "@/models/AwEventStoreV2"

import { RootLogger } from "loglevel"
import {
  getMarketoTrackerId,
  getPardotVisitorId,
  getHubspotTrackerId,
  getEloquaTrackerId
} from "@/services/MarketingAutomationService"
import { getHostUrl } from "@/utils/getHostUrl"
import { v4 as uuid } from "uuid"
import { LocalStorageService } from "@/services/LocalStorageService"
import raiseBrowserEvents from "@/utils/raiseBrowserEvents"

import axios from "axios"

export interface IAnalytics {
  setUp(
    webid: number | null,
    adId: number | null,
    miscId: number | null,
    hubId: number | null,
    url: string,
    logger: RootLogger
  ): void
  register(aw: AwEventStoreV2, webinarId: number | null): void
}
export class Analytics implements IAnalytics {
  private url: string = ""
  private webinarId: number | null = null
  private logger?: RootLogger
  private adId: number | null = null
  private miscId: number | null = null
  private hubId: number | null = null
  private hostUrl: string | undefined = undefined
  public setUp(
    webid: number | null,
    adId: number | null,
    miscId: number | null,
    hubId: number | null,
    url: string,
    logger: RootLogger
  ) {
    this.url = url
    this.webinarId = webid && webid > 0 ? webid : null
    this.adId = adId
    this.miscId = miscId
    this.hubId = hubId
    this.logger = logger
    this.hostUrl = getHostUrl()
  }
  public async register(aw: AwEventStoreV2, webinarId: number | null) {
    var event: AwEventV2 = {
      sessionId: new LocalStorageService().getSessionId(),
      eventType: aw.eventType,
      momentId: aw.momentId,
      start: aw.start,
      end: aw.end,
      type: aw.type,
      command: aw.command,
      webinarId: this.webinarId ?? webinarId,
      adId: this.adId,
      miscId: this.miscId,
      hubId: this.hubId,
      relatedResourceId: aw.relatedResourceId,
      marketoTracking: getMarketoTrackerId(),
      pardotVisitorId: getPardotVisitorId(),
      hubspotTracking: getHubspotTrackerId(),
      eloquaTracking: getEloquaTrackerId(),
      hostUrl: this.hostUrl
    }
    if (event) raiseBrowserEvents({ ...event, sessionId: undefined })
    if (event) await SendAnalytics(event, this.url, this.logger)
  }
}
export class MockAnalytics implements IAnalytics {
  private logger?: RootLogger
  private hostUrl: string | undefined = undefined
  private webinarId: number | null = null
  private hubId: number | null = null

  public setUp(
    webid: number | null,
    adId: number | null,
    miscId: number | null,
    hubId: number | null,
    url: string,
    logger: RootLogger
  ) {
    this.logger = logger
    this.webinarId = webid && webid > 0 ? webid : null
    this.hubId = hubId
    this.hostUrl = getHostUrl()
  }
  public async register(aw: AwEventStoreV2, webinarId: number | null) {
    if (this.logger)
      this.logger.info(
        `Mock Widget Analytics Registered ::webinarId :${this.webinarId ?? webinarId} {host_url: ${this.hostUrl}, hubId: ${
          this.hubId
        }, event : ${JSON.stringify(aw)} }`
      )
    var event: AwEventV2 = {
      sessionId: null,
      eventType: aw.eventType,
      momentId: aw.momentId,
      start: aw.start,
      end: aw.end,
      type: aw.type,
      command: aw.command,
      webinarId: this.webinarId ?? webinarId,
      adId: null,
      miscId: null,
      hubId: this.hubId,
      relatedResourceId: aw.relatedResourceId,
      marketoTracking: undefined,
      pardotVisitorId: undefined,
      hubspotTracking: undefined,
      eloquaTracking: undefined,
      hostUrl: this.hostUrl
    }
    raiseBrowserEvents(event)
  }
}

@singleton()
export class AnalyticsClient {
  private _client: IAnalytics
  constructor(@inject("AnalyticsService") public client: IAnalytics) {
    this._client = client
  }
  public setUp(
    webid: number | null,
    adId: number | null,
    miscId: number | null,
    hubId: number | null,
    url: string,
    logger: RootLogger
  ) {
    this._client.setUp(webid, adId, miscId, hubId, url, logger)
  }
  public async register(aw: AwEventStoreV2, webinarId: number | null) {
    await this._client.register(aw, webinarId)
  }
}

import { AwOptions } from "@/models/AwOptions"

const WIDGET_CLASS = "parmonic-widget"
const INITIALIZED_WIDGET_CLASS = `${WIDGET_CLASS}-initialized`

// registers a setupAmplilyWidget() func on the window object
export function exposeAmplilyWidget() {
  // TODO: rename this to exposeAmplilyWidget()
  ;(window as any).setupAmplilyWidget = (options: AwOptions) => {
    // CASE 1: an explicit element was passed
    //         we only set the widget on that element
    if (options?.element) {
      setupWidgetWithLayout(options.element, options)
    }

    // CASE 2: no elements were explicitly specified
    //         We set the widget on all elements with a .parmonic-widget class
    else {
      let elements = Array.from(document.getElementsByClassName(WIDGET_CLASS))
      elements.forEach(element => {
        setupWidgetWithLayout(element as HTMLElement, options)
      })
    }
  }
  ;(window as any).setupParmonicWidget = (window as any).setupAmplilyWidget
}

/**
 * Reads the element and setsup an appropriate widget
 */
function setupWidgetWithLayout(el: HTMLElement, options: AwOptions) {
  const layout = el.getAttribute("data-layout")
  const windowAsAny = window as any
  if (layout && callIfDefined(windowAsAny.setupAmplilyBundle, options, el, layout)) return

  switch (layout) {
    case "grid":
      callIfDefined(windowAsAny.setupAmplilyGrid, options, el, undefined)
      return
    case "list":
      callIfDefined(windowAsAny.setupAmplilyList, options, el, undefined)
      return
    case "horizontal":
      callIfDefined(windowAsAny.setupAmplilyHorizontal, options, el, undefined)
      return
    case "playlist":
      callIfDefined(windowAsAny.setupAmplilyPlaylist, options, el, undefined)
      return
    case "timeline":
      callIfDefined(windowAsAny.setupAmplilyTimeline, options, el, undefined)
      return
    case "single":
      if (callIfDefined(windowAsAny.setupAmplilyShared, options, el, layout)) {
      } else callIfDefined(windowAsAny.setupAmplilySingle, options, el, undefined)
      return
    case "slider":
      callIfDefined(windowAsAny.setupAmplilySlider, options, el, undefined)
      return
    case "bodymovin":
      callIfDefined(windowAsAny.setupAmplilyShared, options, el, layout)
      return
    case "trailer-timeline":
      callIfDefined(windowAsAny.setupAmplilyTrailerTimeline, options, el, undefined)
      return
    case "reseller":
      callIfDefined(windowAsAny.setupAmplilyReseller, options, el, undefined)
      return
    case "story":
      callIfDefined(windowAsAny.setupAmplilyReseller, options, el, undefined)
      return
    case "hub":
      callIfDefined(windowAsAny.setupAmplilyHub, options, el, undefined)
      return
  }

  console.error("The Parmonic Widget could not be loaded. Check for a missing script.")
}

function callIfDefined(
  func: Function | undefined,
  options: AwOptions,
  el: HTMLElement,
  layout: string | undefined
): boolean {
  if (func) {
    !!layout ? func(options, layout, el) : func(options, el)
    el.classList.replace(WIDGET_CLASS, INITIALIZED_WIDGET_CLASS)
    el.style.width = "100%"
    return true
  }
  return false
}
